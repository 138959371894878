<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-gray-800 text-xl font-bold">{{ $t("settings") }}</h6>
        <button
          @click="save"
          class="rouanded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
          type="button"
        >
          {{ $t("Save settings") }}
        </button>
      </div>
    </div>
    <div
      v-if="currentUser.type && currentUser.type != 'Seller'"
      class="flex-auto px-4 lg:px-10 py-10 pt-0 relative"
    >
      <loading :active.sync="loading" :is-full-page="fullPage"></loading>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        {{ $t("order_fees") }}
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >{{ $t("confirmation_fees") }}
              <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label
            >
            <input
              type="number"
              v-model="formData.detail.order.feesConfirmation"
              min="0"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="fulfillement_fees"
              >{{ $t("fulfillement_fees") }}
              <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label
            >
            <input
              type="number"
              v-model="formData.detail.order.fulfillement_fees"
              min="0"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        {{ $t("shipping_fees") }}
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >{{ $t("shipping_fees") }}
              <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label
            >
            <input
              v-if="coutryW != 'MA'"
              type="number"
              v-model="formData.detail.shipping.fees"
              min="0"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
            <!-- fees Morroc-->
            <div
              v-else
              class="bg-white shadow-md rounded-lg px-3 py-2 mb-4 overflow-y-auto max-height-300"
            >
              <div
                v-for="(zon, index) in cities"
                :key="index"
                class="flex justify-center items-center border-dotted border-4 border-light-blue-500 justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
              >
                <div class="flex-grow w-4/5 font-medium px-2">
                  <span
                    v-for="(city, ind) in zon._id.cities"
                    :key="ind"
                    class="m-1 whitespace-nowrap bg-gray-200 hover:bg-gray-300 rounded-full px-2 font-bold text-sm leading-loose cursor-pointer"
                    >{{ city }}</span
                  >
                </div>
                <div
                  class="py-1 w-1/5 h-8 flex justify-center items-center px-2 rounded-full text-xs bg-green-500 text-white cursor-pointer font-medium"
                >
                  {{ zon._id.price }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >{{ $t("return_fees") }}
              <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label
            >
            <input
              type="number"
              v-model="formData.detail.shipping.refund"
              min="0"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >{{ $t("cancelation_fees") }}
              <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label
            >
            <input
              type="number"
              v-model="formData.detail.shipping.cancelation"
              min="0"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        {{ $t("quality_control") }}
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >{{ $t("quality_control") }}
              <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label
            >
            <input
              type="number"
              v-model="formData.detail.quality_control"
              min="0"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        conversion
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/2 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >conversion</label
            >
            <div class="flex">
              <div
                class="w-1/2 border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 flex flex-wrap items-stretch relative h-15 bg-white items-center rounded"
              >
                <div class="flex -mr-px justify-center p-1 rounded shadow">
                  <span
                    class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600"
                  >
                    <sup class="uppercase"> USD </sup
                    ><span class="select-none ml-2"
                      ><img
                        src="https://static.dwcdn.net/css/flag-icons/flags/4x3/us.svg"
                        class="flag-img"
                    /></span>
                  </span>
                </div>
                <input
                  type="number"
                  v-model="formData.detail.info.usd"
                  disabled
                  min="1"
                  class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none"
                />
              </div>
              <i class="material-icons mx-2 items-center flex-imp"
                >arrow_forward</i
              >
              <div
                class="w-1/2 border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 flex flex-wrap items-stretch relative h-15 bg-white items-center rounded"
              >
                <div class="flex -mr-px justify-center p-1 rounded shadow">
                  <span
                    class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600"
                  >
                    <sup class="uppercase" v-if="idWharhouse">
                      {{ idWharhouse.currency }} </sup
                    ><span class="select-none ml-2"
                      ><img
                        :src="idWharhouse ? idWharhouse.flag : ''"
                        class="flag-img"
                    /></span>
                  </span>
                </div>
                <input
                  type="number"
                  v-model="formData.detail.info.currency"
                  min="0"
                  class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        {{ $t("phone") }}
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
              >{{ $t("phone") }}</label
            >
            <input
              type="text"
              v-model="formData.detail.phone"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        {{ $t("tva") }}
      </h6>
      <div class="flex flex-wrap">
        <div
          class="border border-gray-400 lg:w-4/12 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
        >
          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
            <p>
              <label
                for="cities"
                class="block bg-gray-200 uppercase text-gray-700 text-xs font-bold mb-2"
                >{{ $t("Tva list")
                }}<span :class="$colors.required"> *</span></label
              >
            </p>
          </div>
          <div
            id="tva"
            class="md:w-full overflow-y-auto h-full flex flex-col items-center p-1"
          >
            <div class="w-full">
              <div class="flex flex-col items-center relative">
                <div class="w-full">
                  <div
                    class="mt-2 shadow mb-2 p-1 bg-white flex border border-gray-200 rounded"
                  >
                    <div class="flex flex-auto flex-wrap"></div>
                    <input
                      v-model="tvaInput"
                      @keyup.enter="trigger"
                      placeholder="Enter tva (%)"
                      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                    />
                    <div
                      class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                    >
                      <button
                        @click="addTva"
                        ref="sendReply"
                        class="cursor-pointer w-6 h-6 text-blue-500 outline-none focus:outline-none"
                      >
                        <i class="material-icons">add</i>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Displaying cities -->
                <div
                  v-if="formData.tva.length > 0"
                  class="w-full max-height-200"
                >
                  <div
                    v-for="(t, index) in formData.tva"
                    :key="index"
                    class="flex cursor-pointer my-1 hover:bg-blue-lightest rounded"
                  >
                    <div class="w-8 h-10 text-center py-1 hori-center">
                      <p class="text-3xl p-0 text-grey-dark">•</p>
                    </div>
                    <div class="w-4/5 h-10 py-3 px-1">
                      <p class="hover:text-blue-dark">{{ t }} %</p>
                    </div>
                    <div class="w-1/5 h-10 text-right p-3">
                      <p
                        @click="deleteTva(index)"
                        class="btn-close vs-chip--close"
                        data-role="remove"
                      ></p>
                    </div>
                  </div>
                </div>
                <div v-else class="py-2">
                  <span
                    class="w-full flex justify-center text-center border-gray-200 text-base"
                    ><p
                      class="font-semibold flex text-blue-500 whitespace-no-wrap"
                    >
                      <span>No Tva added </span>
                    </p></span
                  >
                </div>
                <!-- / Displaying cities -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Blacklist phone number
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full my-3">
            <div
              class="border border-gray-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label
                    for="cities"
                    class="bg-gray-200 text-gray-700 px-1 uppercase text-xs font-bold"
                    >Add phone
                    <img
                      v-if="idWharhouse && idWharhouse.flag"
                      class="h-4 w-5 inline-block ml-2"
                      :src="idWharhouse.flag"
                  /></label>
                </p>
              </div>
              <div
                id="city"
                class="md:w-full overflow-y-auto h-full flex flex-col items-center p-1"
              >
                <div class="w-full">
                  <div class="flex flex-col items-center relative">
                    <div class="w-full">
                      <div
                        class="mt-2 mb-4 p-1 bg-white flex border border-gray-200 rounded"
                      >
                        <div class="flex flex-auto flex-wrap"></div>
                        <input
                          v-model="phoneInput"
                          @keyup.enter="trigger"
                          placeholder="Enter phone"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                        <div
                          class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                        >
                          <button
                            @click="addPhones"
                            ref="sendReply"
                            class="cursor-pointer w-6 h-6 text-blue-500 outline-none focus:outline-none"
                          >
                            <i class="material-icons">add</i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Displaying cities -->
                    <div
                      v-if="formData.blacklist_phones.length > 0"
                      class="tag_cities"
                    >
                      <span
                        v-for="(city, index) in formData.blacklist_phones"
                        :key="index"
                        class="tag label label-info"
                      >
                        <span class="text-chip vs-chip--text">{{ city }}</span>
                        <span
                          class="btn-close vs-chip--close"
                          @click="deletePhone(index)"
                          data-role="remove"
                        ></span>
                      </span>
                    </div>
                    <div v-else class="py-2">
                      <span
                        class="w-full flex justify-center text-center border-gray-200 text-base"
                        ><p
                          class="font-semibold flex text-blue-500 whitespace-no-wrap"
                        >
                          <span>No Phone added </span>
                        </p></span
                      >
                    </div>
                    <!-- / Displaying cities -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentUser.type && currentUser.type == 'Seller'"
      class="flex-auto px-4 lg:px-10 py-10 pt-0 relative"
    >
      <loading :active.sync="loading" :is-full-page="fullPage"></loading>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        {{ $t("packages") }}
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              for="authorized_open_package"
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              >{{ $t("authorized_open_package") }}</label
            >
            <!-- Toggle Button -->
            <label for="toogleA" class="flex items-center cursor-pointer">
              <div class="relative">
                <input
                  id="toogleA"
                  type="checkbox"
                  class="hidden"
                  v-model="formData.detailSeller.open_colis"
                />
                <div
                  class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"
                ></div>
                <div
                  class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"
                ></div>
              </div>
              <div class="ml-3 text-gray-700">
                {{ formData.detailSeller.open_colis }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <h6 class="text-gray-500 mb-10 text-sm mt-3 font-bold uppercase">
        Services
      </h6>
      <div class="flex flex-col lg:flex-row">
        <div
          class="intro-y flex-1 box py-16 cursor-pointer zoom-in"
          :class="{ active: formData.detailSeller.confirmation }"
        >
          <div class="check">
            <span class="feather-icon select-none relative"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"
              >
                <polyline points="20 6 9 17 4 12"></polyline></svg
            ></span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
          >
            <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <path d="M16 10a4 4 0 0 1-8 0"></path>
          </svg>
          <div class="text-xl font-medium text-center mt-10">Confirmation</div>
          <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                  <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
          <ul class="text-center mt-4">
            <li>
              <a href="#" class="font-semibold"
                ><i class="material-icons" style="font-size: 13px"
                  >fiber_manual_record</i
                >
                {{ $t("confirmation_fees") }} :
                {{ formData.detail.order.feesConfirmation }}
                <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></a
              >
            </li>
          </ul>
        </div>
        <div
          class="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in"
          :class="{ active: formData.detailSeller.fulfillement }"
        >
          <div class="check">
            <span class="feather-icon select-none relative"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"
              >
                <polyline points="20 6 9 17 4 12"></polyline></svg
            ></span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
          >
            <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <path d="M16 10a4 4 0 0 1-8 0"></path>
          </svg>
          <div class="text-xl font-medium text-center mt-10">Warehouse</div>
          <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                  <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
          <ul class="text-center mt-4">
            <li>
              <a href="#" class="font-semibold"
                ><i class="material-icons" style="font-size: 13px"
                  >fiber_manual_record</i
                >
                {{ $t("fulfillement_fees") }} :
                {{ formData.detail.order.fulfillement_fees }}
                <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></a
              >
            </li>
          </ul>
        </div>
        <div
          class="intro-y flex-1 box py-16 lg:ml-5 cursor-pointer zoom-in"
          :class="{ active: formData.detailSeller.shipping }"
        >
          <div class="check">
            <span class="feather-icon select-none relative"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"
              >
                <polyline points="20 6 9 17 4 12"></polyline></svg
            ></span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
          >
            <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <path d="M16 10a4 4 0 0 1-8 0"></path>
          </svg>
          <div class="text-xl font-medium text-center mt-10">Shipping</div>
          <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                  <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
          <ul class="text-center mt-4">
            <li v-if="coutryW != 'MA'">
              <a href="#" class="font-semibold"
                ><i class="material-icons" style="font-size: 13px"
                  >fiber_manual_record</i
                >
                {{ $t("shipping_fees") }} : {{ formData.detail.shipping.fees
                }}<sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></a
              >
            </li>
            <li>
              <a href="#" class="font-semibold"
                ><i class="material-icons" style="font-size: 13px"
                  >fiber_manual_record</i
                >
                {{ $t("return_fees") }} : {{ formData.detail.shipping.refund }}
                <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></a
              >
            </li>
            <li>
              <a href="#" class="font-semibold"
                ><i class="material-icons" style="font-size: 13px"
                  >fiber_manual_record</i
                >
                {{ $t("cancelation_fees") }} :
                {{ formData.detail.shipping.cancelation }}
                <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppFeatures from "./FeaturesView.vue";
import Integrations from "./Integrations.vue";
import lodash from "lodash";

var phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
const PNF = require("google-libphonenumber").PhoneNumberFormat;
export default {
  name: "Fees",
  components: {
    AppFeatures,
    Integrations,
  },
  data() {
    return {
      formData: {
        details: [],
        dataUser: [],
        detailSeller: {
          open_colis: false,
          confirmation: false,
          fulfillement: false,
          shipping: false,
        },
        detail: {
          shipping: {
            fees: 0,
          },
          order: {
            feesConfirmation: 0,
            fulfillement_fees: 0,
          },
          quality_control: 0,
          phone: null,
          info: { usd: 1, currency: 1 },
        },
        tva: [],
        blacklist_phones: [],
      },
      detail: {
        shipping: {
          fees: 0,
        },
        order: {
          feesConfirmation: 0,
          fulfillement_fees: 0,
        },
        quality_control: 0,
        phone: null,
        info: { usd: 1, currency: 1 },
      },
      detailSeller: {
        open_colis: false,
        confirmation: false,
        fulfillement: false,
        shipping: false,
      },
      loading: false,
      fullPage: false,
      tvaInput: "",
      idWharhouse: null,
      currency: "",
      options: this.$countries,
      cities: [],
      coutryW: "",
      phoneInput: "",
      currentUser: {
        role: {},
      },
    };
  },

  async mounted() {
    await this.getUser();
    await this.getwharhouseId();
    // await this.getCurrency();
    await this.getSettings();
    await this.getCities();
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: lodash.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      await this.getwharhouseId();
      // await this.getCurrency();
      await this.getSettings();
      await this.getCities();
    },
  },
  methods: {
    trigger() {
      this.$refs.sendReply.click();
    },
    addPhones(index) {
      if (
        this.phoneInput === "" ||
        this.phoneInput === null ||
        this.phoneInput.value === 0
      ) {
        alert(`Please enter phone in text box below !! `, "warning");
      } else {
        let tel = phoneUtil.parse(this.phoneInput, this.idWharhouse.country);
        tel = phoneUtil.format(tel, PNF.E164);
        this.formData.blacklist_phones.push(tel);
        this.phoneInput = "";
      }
    },
    deletePhone(index) {
      this.formData.blacklist_phones.splice(index, 1);
    },
    async getUser() {
      const res = await this.$server.me("users");
      //
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    async getCities() {
      const resCities = await this.$server.get("zones", {
        allCitiesAndFees: true,
        country: await this.warhouseSelected,
      });
      if (
        resCities.content.length > 0 &&
        (await this.warhouseSelected) === "MA"
      )
        this.cities = resCities.content;
      else this.cities = [];
    },
    async getCurrency() {
      await this.$store.dispatch("wharhouse/getCurrency").then((res) => {
        this.currency = res;
      });
    },
    getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o.warehouse;
        if (o.warehouse._id) whar = o.warehouse._id;
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    addTva() {
      if (
        this.tvaInput === "" ||
        this.tvaInput === null ||
        this.tvaInput.value === 0
      ) {
        alert(`Please enter tva in text box below !! `, "warning");
      } else if (isNaN(this.tvaInput)) {
        alert(`Please enter a number value`, "warning");
      } else {
        this.formData.tva.push(this.tvaInput);
        this.tvaInput = "";
      }
    },
    deleteTva(index) {
      this.formData.tva.splice(index, 1);
    },
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async changeData(data, warhouse, element) {
      for (var i in data) {
        if (data[i].warehouse == warhouse.toString()) {
          data[i] = element;
          break; //Stop this loop, we found it!
        }
      }
    },
    async save() {
      //get data current warhouse
      if (this.currentUser.type != "Seller") {
        const dataWahrhouse = await this.$server.get("settings", {
          warehouse: this.idWharhouse._id,
        });
        if (dataWahrhouse.content.length > 0) {
          this.formData.action = "Update";
        } else {
          this.formData.action = "Add";
        }
        this.formData.detail.warehouse = this.idWharhouse._id;
        const data = await this.$server.update("settings", this.formData);
        if (data && data._id) {
          await this.getSettings();
          alert(this.$t("setting_updated"), "success");
        }
      } else {
        const userData = await this.$server.get("users", {
          id: this.currentUser._id,
        });
        this.formData.dataUser = userData.content;
        const dataWahrhouse = await this.$server.get("users", {
          id: this.currentUser._id,
          warehouseSettings: this.idWharhouse._id,
        });
        if (dataWahrhouse.content.length > 0) {
          this.changeData(
            this.formData.dataUser.settings,
            this.idWharhouse._id,
            this.formData.detailSeller
          );
        } else {
          this.formData.detailSeller.warehouse = this.idWharhouse._id;
          this.formData.dataUser.settings.push(this.formData.detailSeller);
        }

        const data = await this.$server.update("users", this.formData.dataUser);
        if (data && data._id) {
          await this.getSettings();
          alert(this.$t("setting_updated"), "success");
        }
      }
    },
    async getSettings() {
      this.coutryW = await this.warhouseSelected;
      this.loading = true;
      const data = await this.$server.get("settings");
      this.loading = false;
      const dataWahrhouse = await this.$server.get("settings", {
        warehouse: this.idWharhouse._id,
      });
      this.formData.tva = data.content.tva;
      this.formData._id = data.content._id;
      this.formData.details = data.content.details;
      if (!data.content.blacklist_phones) this.formData.blacklist_phones = [];
      else this.formData.blacklist_phones = data.content.blacklist_phones;

      if (dataWahrhouse.content.length > 0) {
        const currentSettingW = this.getElementInArray(
          dataWahrhouse.content[0].details,
          this.idWharhouse._id
        );
        if (!currentSettingW.info)
          currentSettingW.info = { usd: 1, currency: 1 };
        this.formData.detail = currentSettingW;
      } else
        this.formData.detail = {
          shipping: { fees: 0 },
          order: { feesConfirmation: 0, fulfillement_fees: 0 },
          quality_control: 0,
          phone: null,
          info: { usd: 1, currency: 1 },
        };

      if (this.currentUser.type === "Seller") {
        const dataWahrhouse = await this.$server.get("users", {
          id: this.currentUser._id,
          warehouseSettings: this.idWharhouse._id,
        });
        if (dataWahrhouse.content.length > 0) {
          const currentSettingW = this.getElementInArray(
            dataWahrhouse.content[0].settings,
            this.idWharhouse._id
          );
          this.formData.detailSeller = currentSettingW;
        } else
          this.formData.detailSeller = {
            open_colis: false,
            confirmation: false,
            fulfillement: false,
            shipping: false,
          };
      }
    },
  },
};
</script>

<style scoped>
.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}
.flex-imp {
  display: flex !important;
}
input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}
.hori-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vs-chip--close {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  margin: 0 4px;
  margin-left: 8px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  transition: all 0.3s ease;
}
.vs-chip--close:after {
  content: "x";
  padding: 0px 2px;
  margin-bottom: 3px;
  font-size: 15px;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.max-height-200 {
  max-height: 200px;
}
.max-height-300 {
  max-height: 300px;
}
.tag_cities {
  max-height: 19vh;
  overflow-y: scroll;
}
/* total width */
.tag_cities::-webkit-scrollbar {
  width: 7px;
}

/* scrollbar itself */
.tag_cities::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}
.dtag_cities::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.dropdown_warhouse::-webkit-scrollbar-button {
  display: none;
}
</style>
