var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('h1', {
    staticClass: "text-xl font-semibold"
  }, [_vm._v("integrations & External Apps")]), _c('p', {
    staticClass: "text-gray-500"
  }, [_vm._v("Connect your app to external apps and services")]), _c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8"
  }, [_c('div', {
    staticClass: "bg-white border border-gray-300 shadow rounded-lg"
  }, [_vm._m(0), _c('Divider'), _c('div', {
    staticClass: "px-6 pb-6"
  }, [_c('Button', {
    staticClass: "p-button-outlined p-button-sm",
    attrs: {
      "disabled": _vm.isWooCommerceConnected,
      "label": _vm.isWooCommerceConnected ? 'Connected' : 'Connect'
    },
    on: {
      "click": _vm.openModal
    }
  })], 1)], 1), _c('div', {
    staticClass: "bg-white border border-gray-300 shadow rounded-lg"
  }, [_vm._m(1), _c('Divider'), _c('div', {
    staticClass: "px-6 pb-6"
  }, [_c('Button', {
    staticClass: "p-button-outlined p-button-sm",
    attrs: {
      "label": "Get Webhook URL"
    },
    on: {
      "click": _vm.openWebhookModal
    }
  })], 1)], 1), _c('div', {
    staticClass: "bg-white border border-gray-300 shadow rounded-lg"
  }, [_c('div', {
    staticClass: "p-6 pb-0"
  }, [_c('img', {
    staticClass: "h-16 w-16 mb-4",
    attrs: {
      "src": _vm.shopifyImage,
      "alt": "shopify"
    }
  }), _c('h3', {
    staticClass: "text-xl font-semibold text-gray-800 mb-2"
  }, [_vm._v("Shopify")]), _c('p', {
    staticClass: "text-gray-600"
  }, [_vm._v(" Integrate your app with Shopify to seamlessly manage your e-commerce store. ")])]), _c('Divider'), _c('div', {
    staticClass: "px-6 pb-6"
  }, [_c('Button', {
    staticClass: "p-button-outlined p-button-sm",
    attrs: {
      "label": "connect"
    },
    on: {
      "click": function click($event) {
        return _vm.$vm2.open('ShopifyDocumentation');
      }
    }
  })], 1)], 1)]), _c('Sidebar', {
    staticClass: "p-sidebar-md",
    attrs: {
      "visible": _vm.visible,
      "position": "right"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('h2', {
    staticClass: "font-semibold text-xl"
  }, [_vm._v("Create a connection to WooCommerce")]), _c('div', {
    staticClass: "bg-blue-100 border border-solid border-blue-500 p-2 mt-6"
  }, [_c('ol', {
    staticClass: "text-sm pl-6 list-outside"
  }, [_c('li', {
    staticClass: "mb-4"
  }, [_c('p', {
    staticClass: "my-6"
  }, [_vm._v(" Find your API key at "), _c('strong', [_vm._v("WooCommerce")]), _vm._v(" > "), _c('strong', [_vm._v("Settings")]), _vm._v(" > "), _c('strong', [_vm._v("Advanced")]), _vm._v(" > "), _c('strong', [_vm._v("REST API")]), _vm._v(". "), _c('a', {
    staticClass: "text-blue-600 hover:underline",
    attrs: {
      "href": "https://woocommerce.com/document/woocommerce-rest-api/#section-2",
      "target": "_blank"
    }
  }, [_vm._v("See the WooCommerce docs for more info")]), _vm._v(". "), _c('strong', [_vm._v("Enter the "), _c('em', [_vm._v("Key")]), _vm._v(" and "), _c('em', [_vm._v("Secret")]), _vm._v(" below")]), _vm._v(". ")])]), _c('li', [_c('p', {
    staticClass: "my-6"
  }, [_vm._v(" Enter the domain of your store as the "), _c('strong', [_vm._v("URL")]), _vm._v(" below. For example, if you run a store at "), _c('code', {
    staticClass: "bg-red-100 text-red-800 text-xs p-1 rounded-md"
  }, [_vm._v("https://mystore.com/wp-json/wc/v2/orders")]), _vm._v(", enter "), _c('code', {
    staticClass: "bg-red-100 text-red-800 text-xs p-1 rounded-md"
  }, [_vm._v("https://mystore.com")]), _vm._v(" below. ")])])])]), _c('form', {
    staticClass: "mt-4 space-y-6"
  }, [_c('div', [_c('label', {
    staticClass: "mb-4 font-semibold text-sm"
  }, [_vm._v("Seller")]), _c('Dropdown', {
    staticClass: "w-full",
    attrs: {
      "options": _vm.sellers,
      "optionLabel": "fullName",
      "optionValue": "_id",
      "placeholder": "Select default seller"
    },
    model: {
      value: _vm.woocommerce.sellerId,
      callback: function callback($$v) {
        _vm.$set(_vm.woocommerce, "sellerId", $$v);
      },
      expression: "woocommerce.sellerId"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "mb-4 font-semibold text-sm"
  }, [_vm._v("Key")]), _c('InputText', {
    staticClass: "p-inputtext-sm w-full",
    attrs: {
      "type": "text",
      "required": true
    },
    model: {
      value: _vm.woocommerce.key,
      callback: function callback($$v) {
        _vm.$set(_vm.woocommerce, "key", $$v);
      },
      expression: "woocommerce.key"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "mb-4 font-semibold text-sm"
  }, [_vm._v("Secret")]), _c('InputText', {
    staticClass: "p-inputtext-sm w-full",
    attrs: {
      "type": "text",
      "required": true
    },
    model: {
      value: _vm.woocommerce.secret,
      callback: function callback($$v) {
        _vm.$set(_vm.woocommerce, "secret", $$v);
      },
      expression: "woocommerce.secret"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "mb-4 font-semibold text-sm"
  }, [_vm._v("URL")]), _c('InputText', {
    staticClass: "p-inputtext-sm w-full",
    attrs: {
      "type": "text",
      "required": true
    },
    model: {
      value: _vm.woocommerce.url,
      callback: function callback($$v) {
        _vm.$set(_vm.woocommerce, "url", $$v);
      },
      expression: "woocommerce.url"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-between"
  }, [_c('Button', {
    staticClass: "p-button-text p-button-sm",
    attrs: {
      "disabled": _vm.loading,
      "label": "Cancel",
      "icon": "pi pi-times"
    },
    on: {
      "click": _vm.closeModal
    }
  }), _c('Button', {
    staticClass: "p-button-sm",
    attrs: {
      "disabled": _vm.loading,
      "label": "Save",
      "icon": "pi pi-check",
      "autofocus": "",
      "type": "submit"
    },
    on: {
      "click": _vm.handleSubmitWC
    }
  })], 1)])]), _c('Dialog', {
    attrs: {
      "header": "Your Webhook URL",
      "visible": _vm.isOpenWebhookModal,
      "containerStyle": {
        width: '35vw',
        overflow: 'hidden'
      },
      "modal": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isOpenWebhookModal = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('Button', {
          staticClass: "p-button-text p-button-sm",
          attrs: {
            "label": "No",
            "icon": "pi pi-times"
          },
          on: {
            "click": _vm.closeWebhookModal
          }
        }), _c('Button', {
          staticClass: "p-button-sm",
          attrs: {
            "label": "Yes",
            "icon": "pi pi-check",
            "autofocus": ""
          },
          on: {
            "click": _vm.closeWebhookModal
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "bg-blue-100 border border-solid border-blue-500 p-2"
  }, [_vm._v(" http://localhost:3000/api/webhooks/woocommerce ")])]), _c('vue-modal-2', {
    attrs: {
      "name": "ShopifyDocumentation",
      "headerOptions": {
        title: _vm.$t('Integration Documentation')
      },
      "noFooter": true,
      "modalSize": "xl"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('ShopifyDocumentation');
      }
    }
  }, [_c('div', {
    staticClass: "relative flex flex-col w-full h-fit px-6 pb-6"
  }, [_c('loading', {
    attrs: {
      "active": _vm.generateLoading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.generateLoading = $event;
      }
    }
  }), _c('div', {
    staticClass: "mt-2"
  }, [_c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Integration SHOPIFY with webhook")])]), _c('ol', {
    staticClass: "steps bx--list--ordered--temporary list--ordered--indented"
  }, [_c('li', {
    staticClass: "step stepexpand bx--list__item"
  }, [_c('span', {
    staticClass: "cmd"
  }, [_vm._v("Navigate to "), _c('span', {
    staticClass: "ph menucascade"
  }, [_c('span', {
    staticClass: "ph uicontrol"
  }, [_vm._v("Settings")]), _c('abbr', {
    attrs: {
      "title": "and then"
    }
  }, [_vm._v(" > ")]), _c('span', {
    staticClass: "ph uicontrol"
  }, [_vm._v("Notification")])]), _vm._v(". (In your account in shopify)")])]), _c('li', {
    staticClass: "step stepexpand bx--list__item"
  }, [_c('span', {
    staticClass: "cmd"
  }, [_vm._v("Click "), _c('span', {
    staticClass: "ph uicontrol"
  }, [_vm._v("Create webhook")]), _vm._v(".")])]), _c('li', {
    staticClass: "step stepexpand bx--list__item"
  }, [_c('span', {
    staticClass: "cmd"
  }, [_vm._v("Specify the Event. "), _c('span', {
    staticClass: "ph uicontrol"
  }, [_vm._v("(Choose Order Creation)")]), _vm._v(".")])]), _c('li', {
    staticClass: "step stepexpand bx--list__item"
  }, [_c('span', {
    staticClass: "cmd"
  }, [_vm._v("Provide a URL webhook.")]), _c('div', {
    staticClass: "flex items-center px-2 py-2 justify-start gap-4 rounded",
    staticStyle: {
      "width": "fit-content"
    }
  }, [_c('p', [_vm._v("Copy and paste this link ")]), _c('button', {
    staticClass: "bg-green-400 rounded text-white font-semibold text-sm px-3 py-1",
    on: {
      "click": _vm.CopyCredentials
    }
  }, [_vm._v("copy link")])])]), _c('li', {
    staticClass: "step stepexpand bx--list__item"
  }, [_c('span', {
    staticClass: "cmd"
  }, [_vm._v("Specify Webhook API version. "), _c('span', {
    staticClass: "ph uicontrol"
  }, [_vm._v("(Choose the last one)")]), _vm._v(".")])]), _c('li', {
    staticClass: "step stepexpand bx--list__item"
  }, [_c('span', {
    staticClass: "cmd"
  }, [_vm._v("Click "), _c('span', {
    staticClass: "ph uicontrol"
  }, [_vm._v("Save")]), _vm._v(".")])])]), _c('div', {
    staticClass: "note border-2 bx--inline-notification--info ibmdocs-note bx--inline-notification bx--inline-notification--low-contrast margin-top-1 margin-bottom-1"
  }, [_c('div', {
    staticClass: "bx--inline-notification__details"
  }, [_c('svg', {
    staticClass: "bx--inline-notification__icon",
    staticStyle: {
      "will-change": "transform"
    },
    attrs: {
      "focusable": "false",
      "preserveAspectRatio": "xMidYMid meet",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 32 32",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,7Zm4,17.12H12V21.88h2.88V15.12H13V12.88h4.13v9H20Z"
    }
  })]), _c('div', {
    staticClass: "bx--inline-notification__text-wrapper"
  }, [_c('div', {
    staticClass: "bx--inline-notification__subtitle"
  }, [_c('span', {
    staticClass: "notetitle ibm-bold ibm-textcolor-blue-50 background-blue bx--inline-notification__title ibm-padding-top-1 ibm-padding-bottom-1 padding-left-1 padding-right-1"
  }, [_vm._v("Note:")]), _vm._v(" Don't forget the synchronisation the products between SHOPIFY and afriquecod"), _c('br'), _vm._v(" The product ID SHOPIFY you will find in the link to update the product page (exepmle: https://admin.shopify.com/store/storeino/products/68527971688977) "), _c('br'), _vm._v(" In this case our Shopify product ID is 68527971688977 "), _c('br'), _vm._v(" Now we enter this product ID into our product in "), _c('b', [_vm._v("SpeedLiv")]), _vm._v(" exactly in a field called "), _c('b', [_vm._v("code")]), _vm._v(" and we save the changes ")])])])]), _c('p', {
    staticClass: "bg-red-200 my-4 rounded px-4 py-2",
    staticStyle: {
      "margin-bottom": "0 !important",
      "font-size": "13px"
    }
  }, [_c('strong', [_vm._v("Note")]), _vm._v(": Si vous utilisez le même produit dans plusieurs pays, vous pouvez ajouter "), _c('b', [_vm._v("country code")]), _vm._v(" dans la section "), _c('b', [_vm._v("Vendeur")]), _vm._v(" dans la partie "), _c('b', [_vm._v("edit produit")]), _vm._v(" (exemple: CI) (Dans votre compte Shopify) ")])])], 1)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-6 pb-0"
  }, [_c('img', {
    staticClass: "h-16 w-16 mb-4",
    attrs: {
      "src": "/WooCommerce-Icon.svg",
      "alt": "WooCommerce"
    }
  }), _c('h3', {
    staticClass: "text-xl font-semibold text-gray-800 mb-2"
  }, [_vm._v("WooCommerce")]), _c('p', {
    staticClass: "text-gray-600"
  }, [_vm._v(" Integrate your app with WooCommerce to seamlessly manage your e-commerce store. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-6 pb-0"
  }, [_c('img', {
    staticClass: "h-16 w-16 mb-4",
    attrs: {
      "src": "/Webhook.svg",
      "alt": "WooCommerce"
    }
  }), _c('h3', {
    staticClass: "text-xl font-semibold text-gray-800 mb-2"
  }, [_vm._v("Webhook")]), _c('p', {
    staticClass: "text-gray-600"
  }, [_vm._v(" Use webhooks to get notified when events happen in your app. ")])]);

}]

export { render, staticRenderFns }