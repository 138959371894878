import axios from "../common/axios";

export async function getAllApps() {
  return await axios.get("/integrations/all");
}

export async function connectWooCommerce(data) {
  return await axios.post(
    `/integrations/woocommerce?sellerId=${data.sellerId}`,
    data
  );
}
