var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
  }, [_c('div', {
    staticClass: "rounded-t bg-white mb-0 px-6 py-6"
  }, [_c('div', {
    staticClass: "text-center flex justify-between"
  }, [_c('h6', {
    staticClass: "text-gray-800 text-xl font-bold"
  }, [_vm._v(_vm._s(_vm.$t("settings")))]), _c('button', {
    staticClass: "rouanded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Save settings")) + " ")])])]), _vm.currentUser.type && _vm.currentUser.type != 'Seller' ? _c('div', {
    staticClass: "flex-auto px-4 lg:px-10 py-10 pt-0 relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("order_fees")) + " ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("confirmation_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.order.feesConfirmation,
      expression: "formData.detail.order.feesConfirmation"
    }],
    staticClass: "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.detail.order.feesConfirmation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail.order, "feesConfirmation", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "fulfillement_fees"
    }
  }, [_vm._v(_vm._s(_vm.$t("fulfillement_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.order.fulfillement_fees,
      expression: "formData.detail.order.fulfillement_fees"
    }],
    staticClass: "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.detail.order.fulfillement_fees
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail.order, "fulfillement_fees", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("shipping_fees")) + " ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("shipping_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()]), _vm.coutryW != 'MA' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.shipping.fees,
      expression: "formData.detail.shipping.fees"
    }],
    staticClass: "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.detail.shipping.fees
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail.shipping, "fees", $event.target.value);
      }
    }
  }) : _c('div', {
    staticClass: "bg-white shadow-md rounded-lg px-3 py-2 mb-4 overflow-y-auto max-height-300"
  }, _vm._l(_vm.cities, function (zon, index) {
    return _c('div', {
      key: index,
      staticClass: "flex justify-center items-center border-dotted border-4 border-light-blue-500 justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
    }, [_c('div', {
      staticClass: "flex-grow w-4/5 font-medium px-2"
    }, _vm._l(zon._id.cities, function (city, ind) {
      return _c('span', {
        key: ind,
        staticClass: "m-1 whitespace-nowrap bg-gray-200 hover:bg-gray-300 rounded-full px-2 font-bold text-sm leading-loose cursor-pointer"
      }, [_vm._v(_vm._s(city))]);
    }), 0), _c('div', {
      staticClass: "py-1 w-1/5 h-8 flex justify-center items-center px-2 rounded-full text-xs bg-green-500 text-white cursor-pointer font-medium"
    }, [_vm._v(" " + _vm._s(zon._id.price) + " ")])]);
  }), 0)])]), _c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("return_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.shipping.refund,
      expression: "formData.detail.shipping.refund"
    }],
    staticClass: "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.detail.shipping.refund
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail.shipping, "refund", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("cancelation_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.shipping.cancelation,
      expression: "formData.detail.shipping.cancelation"
    }],
    staticClass: "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.detail.shipping.cancelation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail.shipping, "cancelation", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("quality_control")) + " ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("quality_control")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.quality_control,
      expression: "formData.detail.quality_control"
    }],
    staticClass: "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.detail.quality_control
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail, "quality_control", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" conversion ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-1/2 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v("conversion")]), _c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "w-1/2 border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 flex flex-wrap items-stretch relative h-15 bg-white items-center rounded"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.info.usd,
      expression: "formData.detail.info.usd"
    }],
    staticClass: "flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none",
    attrs: {
      "type": "number",
      "disabled": "",
      "min": "1"
    },
    domProps: {
      "value": _vm.formData.detail.info.usd
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail.info, "usd", $event.target.value);
      }
    }
  })]), _c('i', {
    staticClass: "material-icons mx-2 items-center flex-imp"
  }, [_vm._v("arrow_forward")]), _c('div', {
    staticClass: "w-1/2 border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 flex flex-wrap items-stretch relative h-15 bg-white items-center rounded"
  }, [_c('div', {
    staticClass: "flex -mr-px justify-center p-1 rounded shadow"
  }, [_c('span', {
    staticClass: "flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600"
  }, [_vm.idWharhouse ? _c('sup', {
    staticClass: "uppercase"
  }, [_vm._v(" " + _vm._s(_vm.idWharhouse.currency) + " ")]) : _vm._e(), _c('span', {
    staticClass: "select-none ml-2"
  }, [_c('img', {
    staticClass: "flag-img",
    attrs: {
      "src": _vm.idWharhouse ? _vm.idWharhouse.flag : ''
    }
  })])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.info.currency,
      expression: "formData.detail.info.currency"
    }],
    staticClass: "flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.detail.info.currency
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail.info, "currency", $event.target.value);
      }
    }
  })])])])])]), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("phone")) + " ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("phone")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detail.phone,
      expression: "formData.detail.phone"
    }],
    staticClass: "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.detail.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.detail, "phone", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("tva")) + " ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "border border-gray-400 lg:w-4/12 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "block bg-gray-200 uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "cities"
    }
  }, [_vm._v(_vm._s(_vm.$t("Tva list"))), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v(" *")])])])]), _c('div', {
    staticClass: "md:w-full overflow-y-auto h-full flex flex-col items-center p-1",
    attrs: {
      "id": "tva"
    }
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex flex-col items-center relative"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "mt-2 shadow mb-2 p-1 bg-white flex border border-gray-200 rounded"
  }, [_c('div', {
    staticClass: "flex flex-auto flex-wrap"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.tvaInput,
      expression: "tvaInput"
    }],
    staticClass: "p-1 px-2 appearance-none outline-none w-full text-gray-800",
    attrs: {
      "placeholder": "Enter tva (%)"
    },
    domProps: {
      "value": _vm.tvaInput
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.trigger.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.tvaInput = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
  }, [_c('button', {
    ref: "sendReply",
    staticClass: "cursor-pointer w-6 h-6 text-blue-500 outline-none focus:outline-none",
    on: {
      "click": _vm.addTva
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("add")])])])])]), _vm.formData.tva.length > 0 ? _c('div', {
    staticClass: "w-full max-height-200"
  }, _vm._l(_vm.formData.tva, function (t, index) {
    return _c('div', {
      key: index,
      staticClass: "flex cursor-pointer my-1 hover:bg-blue-lightest rounded"
    }, [_vm._m(1, true), _c('div', {
      staticClass: "w-4/5 h-10 py-3 px-1"
    }, [_c('p', {
      staticClass: "hover:text-blue-dark"
    }, [_vm._v(_vm._s(t) + " %")])]), _c('div', {
      staticClass: "w-1/5 h-10 text-right p-3"
    }, [_c('p', {
      staticClass: "btn-close vs-chip--close",
      attrs: {
        "data-role": "remove"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteTva(index);
        }
      }
    })])]);
  }), 0) : _c('div', {
    staticClass: "py-2"
  }, [_vm._m(2)])])])])])]), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" Blacklist phone number ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full my-3"
  }, [_c('div', {
    staticClass: "border border-gray-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-gray-200 text-gray-700 px-1 uppercase text-xs font-bold",
    attrs: {
      "for": "cities"
    }
  }, [_vm._v("Add phone "), _vm.idWharhouse && _vm.idWharhouse.flag ? _c('img', {
    staticClass: "h-4 w-5 inline-block ml-2",
    attrs: {
      "src": _vm.idWharhouse.flag
    }
  }) : _vm._e()])])]), _c('div', {
    staticClass: "md:w-full overflow-y-auto h-full flex flex-col items-center p-1",
    attrs: {
      "id": "city"
    }
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex flex-col items-center relative"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "mt-2 mb-4 p-1 bg-white flex border border-gray-200 rounded"
  }, [_c('div', {
    staticClass: "flex flex-auto flex-wrap"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phoneInput,
      expression: "phoneInput"
    }],
    staticClass: "p-1 px-2 appearance-none outline-none w-full text-gray-800",
    attrs: {
      "placeholder": "Enter phone"
    },
    domProps: {
      "value": _vm.phoneInput
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.trigger.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.phoneInput = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
  }, [_c('button', {
    ref: "sendReply",
    staticClass: "cursor-pointer w-6 h-6 text-blue-500 outline-none focus:outline-none",
    on: {
      "click": _vm.addPhones
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("add")])])])])]), _vm.formData.blacklist_phones.length > 0 ? _c('div', {
    staticClass: "tag_cities"
  }, _vm._l(_vm.formData.blacklist_phones, function (city, index) {
    return _c('span', {
      key: index,
      staticClass: "tag label label-info"
    }, [_c('span', {
      staticClass: "text-chip vs-chip--text"
    }, [_vm._v(_vm._s(city))]), _c('span', {
      staticClass: "btn-close vs-chip--close",
      attrs: {
        "data-role": "remove"
      },
      on: {
        "click": function click($event) {
          return _vm.deletePhone(index);
        }
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "py-2"
  }, [_vm._m(3)])])])])])])])])], 1) : _vm._e(), _vm.currentUser.type && _vm.currentUser.type == 'Seller' ? _c('div', {
    staticClass: "flex-auto px-4 lg:px-10 py-10 pt-0 relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("packages")) + " ")]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-4/12 px-4"
  }, [_c('div', {
    staticClass: "relative w-full mb-3"
  }, [_c('label', {
    staticClass: "block uppercase text-gray-700 text-xs font-bold mb-2",
    attrs: {
      "for": "authorized_open_package"
    }
  }, [_vm._v(_vm._s(_vm.$t("authorized_open_package")))]), _c('label', {
    staticClass: "flex items-center cursor-pointer",
    attrs: {
      "for": "toogleA"
    }
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.detailSeller.open_colis,
      expression: "formData.detailSeller.open_colis"
    }],
    staticClass: "hidden",
    attrs: {
      "id": "toogleA",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.formData.detailSeller.open_colis) ? _vm._i(_vm.formData.detailSeller.open_colis, null) > -1 : _vm.formData.detailSeller.open_colis
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.formData.detailSeller.open_colis,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formData.detailSeller, "open_colis", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formData.detailSeller, "open_colis", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formData.detailSeller, "open_colis", $$c);
        }
      }
    }
  }), _c('div', {
    staticClass: "toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"
  }), _c('div', {
    staticClass: "toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"
  })]), _c('div', {
    staticClass: "ml-3 text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.detailSeller.open_colis) + " ")])])])])]), _c('hr', {
    staticClass: "mt-6 border-b-1 border-gray-400"
  }), _c('h6', {
    staticClass: "text-gray-500 mb-10 text-sm mt-3 font-bold uppercase"
  }, [_vm._v(" Services ")]), _c('div', {
    staticClass: "flex flex-col lg:flex-row"
  }, [_c('div', {
    staticClass: "intro-y flex-1 box py-16 cursor-pointer zoom-in",
    class: {
      active: _vm.formData.detailSeller.confirmation
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v("Confirmation")]), _c('ul', {
    staticClass: "text-center mt-4"
  }, [_c('li', [_c('a', {
    staticClass: "font-semibold",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("fiber_manual_record")]), _vm._v(" " + _vm._s(_vm.$t("confirmation_fees")) + " : " + _vm._s(_vm.formData.detail.order.feesConfirmation) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])])]), _c('div', {
    staticClass: "intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in",
    class: {
      active: _vm.formData.detailSeller.fulfillement
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v("Warehouse")]), _c('ul', {
    staticClass: "text-center mt-4"
  }, [_c('li', [_c('a', {
    staticClass: "font-semibold",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("fiber_manual_record")]), _vm._v(" " + _vm._s(_vm.$t("fulfillement_fees")) + " : " + _vm._s(_vm.formData.detail.order.fulfillement_fees) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])])]), _c('div', {
    staticClass: "intro-y flex-1 box py-16 lg:ml-5 cursor-pointer zoom-in",
    class: {
      active: _vm.formData.detailSeller.shipping
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v("Shipping")]), _c('ul', {
    staticClass: "text-center mt-4"
  }, [_vm.coutryW != 'MA' ? _c('li', [_c('a', {
    staticClass: "font-semibold",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("fiber_manual_record")]), _vm._v(" " + _vm._s(_vm.$t("shipping_fees")) + " : " + _vm._s(_vm.formData.detail.shipping.fees)), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])]) : _vm._e(), _c('li', [_c('a', {
    staticClass: "font-semibold",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("fiber_manual_record")]), _vm._v(" " + _vm._s(_vm.$t("return_fees")) + " : " + _vm._s(_vm.formData.detail.shipping.refund) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])]), _c('li', [_c('a', {
    staticClass: "font-semibold",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("fiber_manual_record")]), _vm._v(" " + _vm._s(_vm.$t("cancelation_fees")) + " : " + _vm._s(_vm.formData.detail.shipping.cancelation) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])])])])], 1) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex -mr-px justify-center p-1 rounded shadow"
  }, [_c('span', {
    staticClass: "flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600"
  }, [_c('sup', {
    staticClass: "uppercase"
  }, [_vm._v(" USD ")]), _c('span', {
    staticClass: "select-none ml-2"
  }, [_c('img', {
    staticClass: "flag-img",
    attrs: {
      "src": "https://static.dwcdn.net/css/flag-icons/flags/4x3/us.svg"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-8 h-10 text-center py-1 hori-center"
  }, [_c('p', {
    staticClass: "text-3xl p-0 text-grey-dark"
  }, [_vm._v("•")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "w-full flex justify-center text-center border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-blue-500 whitespace-no-wrap"
  }, [_c('span', [_vm._v("No Tva added ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "w-full flex justify-center text-center border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-blue-500 whitespace-no-wrap"
  }, [_c('span', [_vm._v("No Phone added ")])])]);

}]

export { render, staticRenderFns }