<template>
  <div class="features-view">
    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
      Application Features
    </h6>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <div class="space-y-4">
        <h3 id="multi-seller">Multi sellers:</h3>
        <p class="text-sm text-gray-500">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, quia.
        </p>
        <div class="flex flex-wrap gap-4 items-center">
          <InputSwitch
            ariaLabelledBy="multi-seller"
            v-model="multiSeller"
            inputId="multi-seller"
            name="multi-seller"
            @input="
              transform({
                name: 'multi-sellers',
                value: multiSeller,
              })
            "
          />
          <Dropdown
            v-if="!multiSeller"
            :options="sellers"
            optionLabel="fullName"
            optionValue="_id"
            v-model="defaultSeller"
            placeholder="Select default seller"
            class="w-full"
            @change="defineDefaultSeller(defaultSeller)"
          />
        </div>
      </div>
      <div class="space-y-4">
        <h3 id="multi-seller">Multi warehouses:</h3>
        <p class="text-sm text-gray-500">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, quia.
        </p>
        <div class="flex flex-wrap gap-4 items-center">
          <InputSwitch
            v-model="multiWarehouse"
            name="multi-warehouse"
            @input="
              transform({
                name: 'multi-warehouses',
                value: multiWarehouse,
              })
            "
          />

          <Dropdown
            v-if="!multiWarehouse"
            placeholder="Select default warehouse"
            class="w-full"
            :options="warehouses"
            optionLabel="name"
            optionValue="_id"
            v-model="defaultWarehouse"
            @change="defineDefaultWarehouse(defaultWarehouse)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import { mapActions, mapState } from "vuex";
export default {
  name: "FeaturesView",
  components: {
    InputSwitch,
    Dropdown,
  },
  data() {
    return {
      defaultSeller: null,
      defaultWarehouse: null,
      multiSeller: null,
      multiWarehouse: null,
    };
  },
  computed: mapState({
    features: (state) => state.features.all,
    sellers: (state) => state.app.sellers,
    warehouses: (state) => state.app.warehouses,
    mainSeller: (state) => state.app.defaultSeller,
    mainWarehouse: (state) => state.app.defaultWarehouse,
  }),
  methods: {
    ...mapActions("app", ["defineDefaultSeller", "defineDefaultWarehouse"]),
    ...mapActions("features", ["transform"]),
  },
  created() {
    this.multiSeller = this.features["multi-sellers"].isActive;
    this.multiWarehouse = this.features["multi-warehouses"].isActive;
    this.defaultSeller = this.mainSeller;
    this.defaultWarehouse = this.mainWarehouse;
  },
};
</script>

<style>
.p-inputtext {
  padding: 0.65625rem 0.65625rem;
}
</style>
