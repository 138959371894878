<template>
  <div class="card p-6">
    <TabView :lazy="true">
      <TabPanel header="Fees">
        <FeesSettings />
      </TabPanel>
      <TabPanel header="Application">
        <AppSettings />
      </TabPanel>
      <TabPanel header="Integrations">
        <IntegrationsSettings />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import AppSettings from "./FeaturesView.vue";
import IntegrationsSettings from "./Integrations.vue";
import FeesSettings from "./FeesSettings.vue";

export default {
  name: "Settings",
  components: {
    TabView,
    TabPanel,
    AppSettings,
    IntegrationsSettings,
    FeesSettings,
  },
};
</script>
