var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card p-6"
  }, [_c('TabView', {
    attrs: {
      "lazy": true
    }
  }, [_c('TabPanel', {
    attrs: {
      "header": "Fees"
    }
  }, [_c('FeesSettings')], 1), _c('TabPanel', {
    attrs: {
      "header": "Application"
    }
  }, [_c('AppSettings')], 1), _c('TabPanel', {
    attrs: {
      "header": "Integrations"
    }
  }, [_c('IntegrationsSettings')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }