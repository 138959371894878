var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "features-view"
  }, [_c('h6', {
    staticClass: "text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"
  }, [_vm._v(" Application Features ")]), _c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "space-y-4"
  }, [_c('h3', {
    attrs: {
      "id": "multi-seller"
    }
  }, [_vm._v("Multi sellers:")]), _c('p', {
    staticClass: "text-sm text-gray-500"
  }, [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, quia. ")]), _c('div', {
    staticClass: "flex flex-wrap gap-4 items-center"
  }, [_c('InputSwitch', {
    attrs: {
      "ariaLabelledBy": "multi-seller",
      "inputId": "multi-seller",
      "name": "multi-seller"
    },
    on: {
      "input": function input($event) {
        return _vm.transform({
          name: 'multi-sellers',
          value: _vm.multiSeller
        });
      }
    },
    model: {
      value: _vm.multiSeller,
      callback: function callback($$v) {
        _vm.multiSeller = $$v;
      },
      expression: "multiSeller"
    }
  }), !_vm.multiSeller ? _c('Dropdown', {
    staticClass: "w-full",
    attrs: {
      "options": _vm.sellers,
      "optionLabel": "fullName",
      "optionValue": "_id",
      "placeholder": "Select default seller"
    },
    on: {
      "change": function change($event) {
        return _vm.defineDefaultSeller(_vm.defaultSeller);
      }
    },
    model: {
      value: _vm.defaultSeller,
      callback: function callback($$v) {
        _vm.defaultSeller = $$v;
      },
      expression: "defaultSeller"
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "space-y-4"
  }, [_c('h3', {
    attrs: {
      "id": "multi-seller"
    }
  }, [_vm._v("Multi warehouses:")]), _c('p', {
    staticClass: "text-sm text-gray-500"
  }, [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, quia. ")]), _c('div', {
    staticClass: "flex flex-wrap gap-4 items-center"
  }, [_c('InputSwitch', {
    attrs: {
      "name": "multi-warehouse"
    },
    on: {
      "input": function input($event) {
        return _vm.transform({
          name: 'multi-warehouses',
          value: _vm.multiWarehouse
        });
      }
    },
    model: {
      value: _vm.multiWarehouse,
      callback: function callback($$v) {
        _vm.multiWarehouse = $$v;
      },
      expression: "multiWarehouse"
    }
  }), !_vm.multiWarehouse ? _c('Dropdown', {
    staticClass: "w-full",
    attrs: {
      "placeholder": "Select default warehouse",
      "options": _vm.warehouses,
      "optionLabel": "name",
      "optionValue": "_id"
    },
    on: {
      "change": function change($event) {
        return _vm.defineDefaultWarehouse(_vm.defaultWarehouse);
      }
    },
    model: {
      value: _vm.defaultWarehouse,
      callback: function callback($$v) {
        _vm.defaultWarehouse = $$v;
      },
      expression: "defaultWarehouse"
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }